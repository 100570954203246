import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, nextTick, watch, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'InputRangeWidget',
  props: {
    filter: {},
    available: {},
    modelValue: {}
  },
  emits: ["commit", "update:model-value", "change:model-value"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const defaultSliderOptions = {
  tooltip: 'none',
  contained: true,
  'dot-size': 16,
}

const props = __props

const emit = __emit

const internal = ref(
  typeof props.modelValue === 'string' || props.modelValue instanceof String
    ? props.modelValue
    : props.available?.max || ''
)
const precision = computed(() => Math.floor(Math.log10(props.filter.max)))
const interval = computed(() => {
  return Number(String(1).padEnd(Math.ceil((precision.value || 1) / 2), '0'))
})

watch(
  [() => props.available, () => props.modelValue],
  ([newAvailable, newModel], [oldAvailable, oldModel]) => {
    if (newModel) {
      if (newModel !== internal.value) {
        internal.value =
          typeof newModel === 'string' || newModel instanceof String
            ? Number(newModel)
            : Number(newAvailable?.max) || ''
      }
    } else {
      internal.value = Number(newAvailable?.max || 1)
    }
  }
)

const debounceInput = useDebounceFn((q) => updateValue(q), 500)

onMounted(() => {
  emit('update:state', internal.value && internal.value.length)
})

function handleUpdate(value) {
  internal.value = value
  debounceInput(value)
}

function updateValue(value) {
  internal.value = value
  nextTick(() => {
    emit('change:model-value', internal.value)
    apply()
  })
}
function apply() {
  emit('commit')
}

const __returned__ = { defaultSliderOptions, props, emit, internal, precision, interval, debounceInput, handleUpdate, updateValue, apply, computed, onMounted, nextTick, watch, ref, get useDebounceFn() { return useDebounceFn } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})