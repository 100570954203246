import { defineComponent as _defineComponent } from 'vue'
import { toRefs, 
  ref,
  computed,
  useSlots,
} from 'vue'
import type { ComputedRef } from 'vue'

const r = 'required'


export default /*@__PURE__*/_defineComponent({
  __name: 'descriptor',
  props: {
  component: {
    type: String,
    default: 'd-control-simple-input',
  },
  name: {
    type: String,
    required: true,
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
  isSuccess: Boolean,
  disabled: Boolean,
  inputLabel: String,
  staticLabel: {
    type: Boolean,
    default: false,
  },
  options: Array,
  labelBy: String,
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: String, Object,
    default: '',
  },
  setValue: {},
},
  emits: ['updateModel', 'update:modelValue', 'input', 'clearMultiselect'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

const props = __props

const emit = __emit

const { isRequired, id, component, staticLabel } = toRefs(props)

const inputValue = ref(null)
const isValid = ref(null)

const getFP = f => {
  const t = { ...f, onChange: () => {} }
  return t
}

const updateValue = val => {
  inputValue.value = val
  emit('updateModel', val)
}
const updateModelValue = val => {
  inputValue.value = val
  emit('update:modelValue', val)
}

const eSlots = computed(() => {
  return getESlots([...Object.keys(useSlots())])
})


const getProps = f => {
  return f ? f.field : {}
}

const getLabelClass: ComputedRef<string> = computed(() => staticLabel.value ? "d-control-input__label--static": "d-control-input__label--bound")



const __returned__ = { eSlotsResolver, getESlots, props, emit, isRequired, id, component, staticLabel, inputValue, r, isValid, getFP, updateValue, updateModelValue, eSlots, getProps, getLabelClass, toRefs, ref, computed, useSlots }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})