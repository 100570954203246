import { defineComponent as _defineComponent } from 'vue'
import { ref, useSlots } from 'vue'

const r = 'required'


export default /*@__PURE__*/_defineComponent({
  __name: 'CDescriptor',
  props: {
  component: {
    type: [String, Object],
    default: 'ControlInput',
  },
  name: String,
  errors: {
    type: Object,
    default: () => ({}),
  },
  innerElement: {
    type: String,
    default: 'input',
  },
  isSuccess: Boolean,
  inputLabel: String,
  staticLabel: {
    type: Boolean,
    default: true,
  },
  options: Array,
  labelBy: String,
  hint: String,
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: [String, Object],
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  labelStyling: {
    type: String,
    default: 'default',
  },
},
  emits: ["updateModel"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit
const inputComponent = ref(props.component)

const inputValue = ref<string | number | { [key: string]: string | number } | boolean | null>(null)
const isRequired =
  typeof props.rules == 'string' ? props.rules.includes(r) : props.rules?.[r]
const isValid = ref(null)

const updateValue = (
  val: string | number | { [key: string]: string | number } | boolean
): void => {
  inputValue.value = val
  emit('update:modelValue', val)
}


const __returned__ = { props, emit, inputComponent, inputValue, r, isRequired, isValid, updateValue, ref, useSlots }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})