import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, nextTick, watch, ref } from 'vue'
// import SelectionController from '@packages/vue-selection-controller/component/src/main'

const LIMIT_FOR_SEARCH = 20


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'SelectWidget',
  props: {
    filter: {},
    available: {},
    modelValue: {},
    isHideUnavailable: { type: Boolean },
    isDynamic: { type: Boolean }
  },
  emits: ["commit", "update:model-value", "change:model-value"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const props = __props

const emit = __emit

const checkDisabled = (x) => !availableIds.value.includes(x.id)
const checkSelected = (x: number) => internal.value && internal.value.id == x

const availableIds = computed(() => {
  if (props.isDynamic) {
    return props.available || []
  }
  return (props.available || []).map(([id]) => id)
})

// const internal = ref((props.modelValue || []).map(el => el.slug))
const internal = ref(props.modelValue || null)

watch(
  () => props.modelValue,
  (newVal) => {
    internal.value = props.modelValue || null
    emit('update:state', !!internal.value)
  }
)

const listChoices = ref(props.filter.choices)
const options = ref(props.filter.choices)

function isAvailable(option) {
  return checkSelected(option.id) || availableIds.value.includes(option.id)
}

const sortByAvailability = (a, b) => isAvailable(b) - isAvailable(a)

const sortByAlpha = (a, b) =>
  (a.value_verbose || a.title || '').localeCompare(
    b.value_verbose || b.title || ''
  )

const sortedOptions = computed(() => {
  let opts = []
  if (props.isHideUnavailable) {
    opts = (options.value || []).filter((el) => isAvailable(el))
  } else {
    opts = (options.value || []).map((el) => {
      el['$isDisabled'] = !isAvailable(el)
      return el
    })
  }

  return [...opts].sort(sortByAlpha).sort(sortByAvailability)
})

onMounted(() => {
  emit('update:state', !!(internal.value && internal.value.length))
})

function updateValue() {
  emit('change:model-value', internal.value)
  apply()
}
function apply() {
  emit('commit')
}
function update(isChecked, option) {
  if (isChecked) {
    internal.value = [...internal.value, option]
  } else {
    internal.value = internal.value.filter((el) => el.id !== option.id)
  }
  updateValue()
}

const slugGetter = (el) => el.slug

const __returned__ = { LIMIT_FOR_SEARCH, props, emit, checkDisabled, checkSelected, availableIds, internal, listChoices, options, isAvailable, sortByAvailability, sortByAlpha, sortedOptions, updateValue, apply, update, slugGetter, computed, onMounted, nextTick, watch, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})