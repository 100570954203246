import { defineComponent as _defineComponent } from 'vue'
import { defineAsyncComponent, watch, ref } from 'vue'
import { useResourceLoader } from '@/composables/useResourceLoader'
import { baseResource } from '@/plugins/resource'
import CatalogFilters from './CatalogFilters.vue'


import type { Pagination } from '@/const'


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogController',
  props: {
    initialPagination: {},
    filtersList: {},
    urlWithoutPage: {},
    resource: {},
    initialParameters: {},
    catalogFiltersComponent: { default: CatalogFilters },
    page: {}
  },
  emits: ["update:parameters"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit
const pagination = ref<Pagination>(props.initialPagination)
const parameters = ref<{ [key: string]: string | string[] }>(
  props.initialParameters && props.initialParameters.filters || {}
)
const { load, loading, result } = useResourceLoader({
  resource: props.resource,
})

watch(
  () => props.page || 1,
  (newVal) => {
    if (newVal && (newVal - 1) * pagination.value.limit !== pagination.value.offset) {
      pagination.value = {
        ...pagination.value,
        offset: (newVal - 1) * pagination.value.limit,
      }
      load({ filters: parameters.value, ...pagination.value }).then(
        ({ data, pagination: limitOffset }: { data: string; pagination: Pagination }) => {

          pagination.value = limitOffset
        }
      )
    }
  },
  {
    deep: true,
  }
)
function handlePagination(pagin: Pagination) {
  pagination.value = {
    ...pagination.value,
    ...pagin,
  }
  load({ filters: parameters.value, ...pagination.value }).then(
    ({ data, pagination: limitOffset }: { data: string; pagination: Pagination }) => {
      emit('update:parameters', {
        filters: parameters.value,
        ...pagination.value,
      })
      pagination.value = limitOffset
    }
  )
}
watch(
  () => props.initialParameters,
  (newVal) => {
    if (
      newVal?.filters &&
      JSON.stringify(newVal.filters) !== JSON.stringify(parameters.value)
    ) {
      parameters.value = {
        ...newVal.filters,
      }

      load({ filters: parameters.value, ...pagination.value }).then(
        ({
          data,
          pagination: pagin,
        }: {
          data: string
          pagination: Pagination
        }) => {
          pagination.value = pagin
        }
      )
    }
  },
  {
    deep: true,
  }
)
function resetAllFilters() {
  parameters.value = {

  }
  load({ filters: parameters.value, ...pagination.value }).then(
    ({ data, pagination: pagin }: { data: string; pagination: Pagination }) => {
      pagination.value = pagin
    }
  )
  emit('update:parameters', { filters: parameters.value })
}

function handleFilters(filters: any) {
  pagination.value.offset = 0
  parameters.value = {
    ...parameters.value,
    ...filters,
  }
  load({ filters: parameters.value, ...pagination.value }).then(
    ({ data, pagination: pagin }: { data: string; pagination: Pagination }) => {
      pagination.value = pagin
    }
  )
  emit('update:parameters', { filters: parameters.value })
}

const topOfContentRef = ref<HTMLElement>()

function scrollToTop() {
  topOfContentRef.value?.scrollIntoView({ behavior: 'smooth' })
}

const __returned__ = { props, emit, pagination, parameters, load, loading, result, handlePagination, resetAllFilters, handleFilters, topOfContentRef, scrollToTop, defineAsyncComponent, watch, ref, get useResourceLoader() { return useResourceLoader }, get baseResource() { return baseResource }, CatalogFilters }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})