import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AppIcon',
  props: {
  name: {
    type: String,
    required: true,
  },
  size: {
    type: [Array, String],
    default: () => ['md'],
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props
const inlinerSizes = computed(() => {
  if (Array.isArray(props.size)) {
    return props.size.map((size: string) => `ds-inliner--size_${size}`)
  }
  return `ds-inliner--size_${props.size}`
})

const __returned__ = { props, inlinerSizes, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})