import { defineComponent as _defineComponent } from 'vue'
import { ref, nextTick, watch } from 'vue'
import type { Pagination } from '@/const'

// import InfiniteTemplateResultsCollector from '@md/resources/components/InfiniteTemplateResultsCollector.js';

// const Renderer = () => import('./Renderer.vue');


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogContent',
  props: {
    loading: {},
    items: {},
    pagination: {},
    isFiltered: {},
    scrollPaginate: {},
    urlWithoutPage: {}
  },
  emits: ["update:pagination", "scroll-to-top"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props
const emit = __emit
const isInfinityTemplate = ref(false)
const startPage = ref(
  Math.floor(props.pagination?.offset / props.pagination?.limit) + 1
)

watch(
  () => props.isFiltered?.value,
  (val) => {
    isInfinityTemplate.value = !val
  }
)
function scrollToTop() {
  nextTick(() => {
    emit('scroll-to-top')
  })
}
function handleLoadMore({ offset }: { offset: number }) {
  handlePaginate({ offset }, true)
}
function handlePaginate({ offset }: { offset: number }, isLoadMore = false) {
  if (props.loading.value) {
    return
  }
  const init = 0
  const { limit = init } = props.pagination
  isInfinityTemplate.value = isLoadMore
  if (!isLoadMore) {
    nextTick(() => {
      startPage.value = Math.floor(offset / limit) + 1
    })
    scrollToTop()
  }
  emit('update:pagination', {
    limit,
    offset,
    page: Math.floor(offset / limit) + 1,
    infinite: isLoadMore,
  })
}

const __returned__ = { props, emit, isInfinityTemplate, startPage, scrollToTop, handleLoadMore, handlePaginate, ref, nextTick, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})