import { defineComponent as _defineComponent } from 'vue'
import { equals } from 'ramda'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, watch, ref } from 'vue'

interface Element {
  label: string
  value: string | string[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectWidget',
  props: {
    name: {},
    items: {},
    title: {},
    state: {},
    value: {},
    dependencyKey: {},
    dependencyValue: {}
  },
  emits: ["input"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const { t } = useI18n()

const changed = ref<boolean>(false)
const opened = ref<boolean>(false)
const emptyOption = { label: t('All'), value: undefined }

const options = computed(() => {
  let items = props.items
  if (props.dependencyKey) {
    items = props.items.filter(el => {
      if (Array.isArray(props.dependencyValue)) {
        if (!props.dependencyValue.length) {
          return true
        } else {
          return props.dependencyValue.includes(el[props.dependencyKey])
        }
      }
      if (!props.dependencyValue) {
        return true
      }
      return el[props.dependencyKey] === props.dependencyValue
    })
  }
  return [emptyOption, ...items]
})

const internal = ref()
onMounted(() => {
  internal.value = getInternal()
})

watch(
  () => props.value,
  () => {
    internal.value = getInternal()
  }
)

watch(
  () => props.dependencyValue,
  () => {
    emit('input', undefined)
  }
)

const getInternal = () => {
  if (props.value && options.value) {
    if (Array.isArray(props.value)) {
      if (!props.value) {
        return emptyOption
      } else {
        return (
          props.value &&
          options.value.find((el) => {
            return el.value === props.value[0]
          })
        )
      }
    }
    return (
      props.value &&
      options.value.find((el) => {
        return el.value === props.value
      })
    )
  }
  if (undefined === props.value) {
    return emptyOption
  }
  return null
}

function update(value: Element) {
  emit('input', value?.value || undefined)
}

function input(value: Element) {
  changed.value = true
  internal.value = value
}

function open() {
  opened.value = true
}
function close() {
  opened.value = false

  if (changed.value) {
    update(internal.value)
  }

  changed.value = false
}

const __returned__ = { props, emit, t, changed, opened, emptyOption, options, internal, getInternal, update, input, open, close, get equals() { return equals }, get useI18n() { return useI18n }, computed, onMounted, watch, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})