
import { defineComponent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainPageCategoryStore } from './store'
import { Category } from './types'

export default defineComponent({
  props: {
    initialCategory: {
      type: Object,
    },
    radioPrefixName: {
      type: String,
      default: 'rg-'
    },
    categories: {
      type: Array,
    },
  },
  setup(props: { initialCategory: Category; categories: Category[]; radioPrefixName: string }, { slots }) {
    const None = undefined

    const mainPageCategoryStore = useMainPageCategoryStore()
    const { category: activeCategory } = storeToRefs(mainPageCategoryStore)

    onMounted(() => {
      activeCategory.value = props.initialCategory
    })

    const listRef = ref(null)

    function onChangeCategory(category: Category) {
      if (JSON.stringify(activeCategory.value) !== JSON.stringify(category)) {
        activeCategory.value = category
      }
    }

    function setFocus(category: Category) {
      const node = listRef.value.querySelector(`[name=${props.radioPrefixName}${category.slug}]`)
      if (node) {
        node?.focus?.()
      }
    }

    function onKeypress(event: KeyboardEvent, category: Category, index: number) {
      let newIndex = index
      switch (event.key) {
        case '32': // space
        case 'Enter': // return
          onChangeCategory(category)
          break

        case 'ArrowUp': // up
        case 'ArrowLeft': // left
          newIndex = index - 1 >= 0 ? index - 1 : props.categories.length - 1
          onChangeCategory(props.categories[newIndex])
          setFocus(props.categories[newIndex])
          break

        case 'ArrowDown': // down
        case 'ArrowRight': // right
          newIndex = index + 1 > props.categories.length - 1 ? 0 : index + 1
          onChangeCategory(props.categories[newIndex])
          setFocus(props.categories[newIndex])
          break

        default:
          break
      }
      if ('Tab' !== event.key) {
        event.stopPropagation()
        event.preventDefault()
      }
    }

    return () => slots?.default?.({
      activeCategory: activeCategory.value,
      listRef,
      radioPrefixName: props.radioPrefixName,
      onChangeCategory,
      onKeypress,
    })
  },
})
