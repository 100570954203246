import { defineComponent as _defineComponent } from 'vue'
import { watch, onMounted, ref, unref } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { isEmpty } from 'ramda'
import { catalogApi } from '@/services/catalog.service'
import {
  useGenerator,
  type GeneratorConfig,
} from '@packages/vue-filters-generator/src/lib'
import { useLoadingState } from '@/packages/vue-loading-state'
import FilterWrapper from '../widgets/FilterWidgetWrapper.vue'
import { WIDGETS_MAP } from '../widgets'


export default /*@__PURE__*/_defineComponent({
  __name: 'FiltersController',
  props: {
    filtersList: {},
    parameters: {},
    available: { default: () => ({}) },
    urlWithoutPage: {},
    tokenString: {},
    searchString: {}
  },
  emits: ["update:parameters", "reset:parameters", "update:checked-filters", "update:available-filters"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const availableFilters = ref({})
const filterValue = ref(props.parameters)

const availableLoadingState = useLoadingState()

function getAvailable() {
  availableLoadingState
    .load(
      catalogApi.offers.catalogFiltersAvailable.execute({
        token_string: props.tokenString,
        search_string: props.searchString,
      })
    )
    .then(({ items: { available, checkFilterParameters } }) => {
      // availableFilters.value = {
      //   ...available,
      //   payment: available['downPayment']
      // }
      emit('update:available-filters', {
        ...available,
      })
      emit('update:checked-filters', checkFilterParameters)
    })
}

watch(
  () => props.tokenString,
  () => {
    getAvailable()
  },
  { immediate: true }
)
watch(
  () => props.parameters,
  (newVal) => {
    filterValue.value = newVal
    value.value = newVal
  },
  {
    deep: true,
  }
)

function onApply(val) {
  filterValue.value = val
  emit('update:parameters', val)
}

const dummyAvailable = ref([])

const generatorConfig: GeneratorConfig = {
  widgetsMap: WIDGETS_MAP,
  filters: props.filtersList,
  onApply,
  available: dummyAvailable,
  typeGetter: (el) => el.widget_type,
  keyGetter: (el) => el.slug,
}

const { keyGetter, typeGetter, nodes, value } = useGenerator(
  generatorConfig,
  filterValue
)

const isOpen = ref(false)

function openFilterModal() {
  isOpen.value = true
}
function resetFilters() {
  emit('reset:parameters', {})
}

const isDesktop = ref(true)
function checkWidth() {
  isDesktop.value = window.innerWidth >= 1200
}
onMounted(() => {
  checkWidth()
})

const __returned__ = { props, emit, availableFilters, filterValue, availableLoadingState, getAvailable, onApply, dummyAvailable, generatorConfig, keyGetter, typeGetter, nodes, value, isOpen, openFilterModal, resetFilters, isDesktop, checkWidth, watch, onMounted, ref, unref, get VueFinalModal() { return VueFinalModal }, get isEmpty() { return isEmpty }, get catalogApi() { return catalogApi }, get useGenerator() { return useGenerator }, get useLoadingState() { return useLoadingState }, FilterWrapper, get WIDGETS_MAP() { return WIDGETS_MAP } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})