import { defineComponent as _defineComponent } from 'vue'
import { computed, nextTick, defineAsyncComponent, watch, ref } from 'vue'
import { pipe, map, equals } from 'ramda'
import { useLocalStorage } from '@vueuse/core'
import { useResourceLoader } from '@/composables/useResourceLoader'
import { baseResource } from '@/plugins/resource'
import type { Pagination } from '@/const'
import { useLoadingState } from '@/packages/vue-loading-state'
import { savedSearchApi } from '@/services/savedSearch.service'
import { LOCAL_STORAGE_OFFERS_VIEW, OFFERS_SORT_MAP } from '@/const'
import { filtersTransformFrom } from '../utils'
import { IS_AGENT, IS_MANAGER } from '@/modules/user/accessTokens'

import {
  selectSlugFrom,
  brandsModelsFrom,
  multiselectSlugFrom,
  sameTransformer,
  simpleTransformer,
  checkEmpty,
  rangeFrom,
} from '@/utils/transformers'
import { useModalOpener } from '@/composables/useModalOpener'
import { isPermittedSimple } from '@/modules/user/permissions'
import { SAVED_SEARCH } from '@/modules/user/accessTokens'


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogController',
  props: {
    initialPagination: {},
    filtersList: {},
    urlWithoutPage: {},
    tokenString: {},
    resource: {},
    initialParameters: {},
    page: {},
    category: {},
    owner: {},
    mainAttributesLabels: {},
    hideSavedSearchBtn: { type: Boolean }
  },
  emits: ["update:parameters"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const Chips = defineAsyncComponent(() => import('../chips/view.vue'))

const UiMessageModal = defineAsyncComponent(
  () => import('@/components/ui/modals/UiMessageModal.vue')
)

const { IS_AUTH } = window

const TRANSFORMERS_MAP = {
  select: pipe(selectSlugFrom, checkEmpty),
  multiselect: pipe(multiselectSlugFrom, checkEmpty),
  'brands-models': pipe(brandsModelsFrom, checkEmpty),
  range: pipe(sameTransformer, checkEmpty),
  'date-range': pipe(rangeFrom, checkEmpty),
  'input-range': pipe(sameTransformer, checkEmpty),
}

const props = __props

const emit = __emit

const topOfContentRef = ref<HTMLElement>()
function scrollToTop() {
  topOfContentRef.value?.scrollIntoView({ behavior: 'smooth' })
}

const pagination = ref<Pagination>(props.initialPagination)

const { load, loading, result } = useResourceLoader({
  resource: props.resource,
})
const selectedFilters = ref({})
const availableFilters = ref({})

const parameters = ref({})
const sorting = ref({
  sort: undefined,
})
const searchString = ref({
  search: undefined,
})
const searchId = ref({
  search_id: undefined
})

function handlePagination(pagin: Pagination) {
  pagination.value = {
    ...pagination.value,
    ...pagin,
  }
  handleUpdate()
  loadResult(prepare())
}

function handleOrder(sort) {
  pagination.value.offset = 0
  if (sort === sorting.value.sort) {
    sorting.value = {}
  } else {
    sorting.value.sort = sort
  }
  handleUpdate()
  loadResult(prepare())
}

function resetSearch() {
  searchString.value.search = undefined
  handleUpdate()
}

function resetAllFilters(filters: any) {
  pagination.value.offset = 0
  parameters.value = {
    ...filters,
    category: props.category,
  }
  searchString.value.search = undefined
  searchId.value.search_id = undefined
  handleUpdate()
}

function handleFilters(filters: any) {
  pagination.value.offset = 0
  searchId.value.search_id = undefined
  parameters.value = {
    ...filters,
    category: props.category,
  }
  handleUpdate()
}

function getInitialQuery() {
  const searchParams = window.location.search
  const params = new URLSearchParams(searchParams)
  const sort = params.get('sort')
  const search = params.get('search')
  const search_id = params.get('search_id')
  sorting.value.sort = sort
  searchId.value.search_id = search_id
  searchString.value.search = search
}
getInitialQuery()

function prepare() {
  const val = parameters.value
  const transformed = props.filtersList.reduce((acc, filter) => {
    if (val[filter.slug]) {
      const value = TRANSFORMERS_MAP?.[filter.widget_type]?.(
        JSON.parse(JSON.stringify(val[filter.slug]))
      )
      if (value) {
        if ('brands' === filter.slug) {
          acc = { ...acc, brands_models: value }
        } else {
          if (Array.isArray(value)) {
            acc[filter.slug] = value
          } else if (
            value instanceof Object &&
            (Object.hasOwn(value, 'min') || Object.hasOwn(value, 'max'))
          ) {
            acc[`${filter.slug}-min`] = value.min ? [value.min] : undefined
            acc[`${filter.slug}-max`] = value.max ? [value.max] : undefined
          } else {
            acc[filter.slug] = [value]
          }
        }
      }
    }
    return acc
  }, {})

  const params = Object.keys(transformed).reduce((acc, key) => {
    if ('category' === key) {
      acc[key] = transformed[key]
      return acc
    }
    const values = transformed[key] ? Array.from(transformed[key]) : undefined
    if (values && values.length) {
      if (props.mainAttributesLabels.includes(key)) {
        acc[key] = values
      } else {
        if (!acc['filters']) {
          acc['filters'] = {}
        }
        acc['filters'][key] = values
      }
    }
    return acc
  }, {})
  return params
}
function handleUpdate() {
  const params = prepare()
  emit('update:parameters', {
    ...params,
    ...pagination.value,
    ...sorting.value,
    ...searchString.value,
    ...searchId.value,
    category: props.category,
    owner: props?.owner,
  })
}

function handleCheckedFilters(checkedFilters) {
  selectedFilters.value = checkedFilters
  const sort = parameters.value.sort
  const keys = Object.keys(parameters.value)
  if (keys.length) {
    Object.entries(parameters.value).forEach(([key, val]) => {
      const newVal = filtersTransformFrom({ [key]: selectedFilters.value[key] }, props.filtersList)
      if (!equals(newVal[key], parameters.value[key])) {
        parameters.value[key] = newVal[key]
      }
    })

  } else {
    parameters.value = filtersTransformFrom(
      selectedFilters.value,
      props.filtersList
    )
  }
  // parameters.value = filtersTransformFrom(
  //   selectedFilters.value,
  //   props.filtersList
  // )
  parameters.value.sort = sort
}

function handleAvailableFilters(available) {
  availableFilters.value = available
}
  
function loadResult(params) {
  load({
    ...params,
    ...pagination.value,
    ...sorting.value,
    ...searchId.value,
    ...searchString.value,
    category: props.category,
    owner: props.owner,
  }).then(
    ({
      data,
      pagination: limitOffset,
    }: {
      data: string
      pagination: Pagination
    }) => {
      pagination.value = limitOffset
    }
  )
}

// handle update
watch([
  () => props.tokenString,
  () => props.initialPagination,
],
  ([newVal, newPagin]) => {
    pagination.value = newPagin 
    const [, part] = newVal.split(`/${props.category}/`)
    getInitialQuery()
    nextTick(() => {
      load({
        token_string: part,
        ...searchString.value,
        ...sorting.value,
        ...searchId.value,
        category: props.category,
        owner: props.owner,
        ...pagination.value
      }).then(
        ({
          data,
          pagination: limitOffset,
        }: {
          data: string
          pagination: Pagination
        }) => {
          pagination.value = limitOffset
        }
      )
    })
  }
)

// toggle view

const storageOffersView = useLocalStorage<boolean>(LOCAL_STORAGE_OFFERS_VIEW, false)

function toggleWideViewProducts(state: boolean) {
  storageOffersView.value = state
}

// saved-search
const savedSearchLoadingState = useLoadingState()

function createSavedSearch() {
  
  const params = {
    url: window.location.toString(),
  }
  savedSearchLoadingState.load(savedSearchApi.create.execute({}, params)).then(
    ({
      data: {
        messages: [message],
      },
    }) => {
      const { open } = useModalOpener({
        name: 'UiMessageModal',
        component: UiMessageModal,
        message,
      })
      open()
    }
  )
}

const __returned__ = { Chips, UiMessageModal, IS_AUTH, TRANSFORMERS_MAP, props, emit, topOfContentRef, scrollToTop, pagination, load, loading, result, selectedFilters, availableFilters, parameters, sorting, searchString, searchId, handlePagination, handleOrder, resetSearch, resetAllFilters, handleFilters, getInitialQuery, prepare, handleUpdate, handleCheckedFilters, handleAvailableFilters, loadResult, storageOffersView, toggleWideViewProducts, savedSearchLoadingState, createSavedSearch, computed, nextTick, defineAsyncComponent, watch, ref, get pipe() { return pipe }, get map() { return map }, get equals() { return equals }, get useLocalStorage() { return useLocalStorage }, get useResourceLoader() { return useResourceLoader }, get baseResource() { return baseResource }, get useLoadingState() { return useLoadingState }, get savedSearchApi() { return savedSearchApi }, get LOCAL_STORAGE_OFFERS_VIEW() { return LOCAL_STORAGE_OFFERS_VIEW }, get OFFERS_SORT_MAP() { return OFFERS_SORT_MAP }, get filtersTransformFrom() { return filtersTransformFrom }, get IS_AGENT() { return IS_AGENT }, get IS_MANAGER() { return IS_MANAGER }, get selectSlugFrom() { return selectSlugFrom }, get brandsModelsFrom() { return brandsModelsFrom }, get multiselectSlugFrom() { return multiselectSlugFrom }, get sameTransformer() { return sameTransformer }, get simpleTransformer() { return simpleTransformer }, get checkEmpty() { return checkEmpty }, get rangeFrom() { return rangeFrom }, get useModalOpener() { return useModalOpener }, get isPermittedSimple() { return isPermittedSimple }, get SAVED_SEARCH() { return SAVED_SEARCH } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})