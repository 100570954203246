import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDebounceFn, useFocus } from '@vueuse/core'
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy'
// import { vOnClickOutside } from '@vueuse/components'
import { catalogApi } from '@/services/catalog.service'
import { pluralization } from '@utils/intl-helpers'
import { useLoadable } from '@/packages/vue-loading-state'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchView',
  props: {
    url: {},
    category: {},
    baseCatalogUrl: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const { t } = useI18n()

const FinancingPeriodPlurals = {
  one: t('month'),
  few: t('months'),
  many: t('months'),
}

const inputRef = ref<HTMLElement | null>(null)
const isShow = ref<boolean>(false)
const isFocused = ref<boolean>(false)
const searchValue = ref('')
const category = ref(props.category)

const preparedLink = computed(() => {
  const [baseUrl] = props.baseCatalogUrl.split('dummy/')
  const query = new URLSearchParams()
  const search = searchValue.value
  query.set('search', search)
  if (category.value?.slug) {
    return `${baseUrl}${category.value?.slug}?${query.toString()}`
  }
  return `${props.url}?${query.toString()}`
})

const { load, loading, result } = useLoadable(getResult)

const debounceSearch = useDebounceFn((q) => {
  return q.length > 2 && handleLoad(q)
}, 500)

function getResult(...args: any[]) {
  const cancelController = new AbortController()
  const resource = catalogApi.offers.list.config(
    'signal',
    cancelController.signal
  )
  const request = cancellablePromiseProxy(
    new Promise((resolve, reject) => {
      resource.execute(...args).then(resolve, reject)
    }),
    { cancelController }
  )
    .then((res) => res)
    .catch((err) => err)
  return request
}

function handleInput(e: InputEvent) {
  searchValue.value = (e.target as HTMLInputElement).value
  debounceSearch(searchValue.value)
}
function handleLoad(search_string) {
  let token_string = undefined
  if (category.value) {
    token_string = `/${category.value.slug}/`
  }
  load({ search_string: searchValue.value, token_string, limit: 20 })
}

function handleFocus() {
  isShow.value = true
  isFocused.value = true
  if (result.value?.items?.length) {
    document.body.classList.add('is-active-search')
  }
}

function handleInputBlur() {
  isFocused.value = false
}

function handleBlur() {
  isShow.value = false
  document.body.classList.remove('is-active-search')
}

function resetCategory() {
  category.value = null
  if (searchValue.value) {
    handleLoad(searchValue.value)
  }
}

function resetSearch() {
  searchValue.value = ''
  result.value = null
  // remove if don't necessary set category
  category.value = props.category
  inputRef.value?.querySelector?.('#header-search-input').focus?.()
}

function handleDown() {
  currentIndexFocus.value =
    currentIndexFocus.value + 1 > result.value.items.length - 1
      ? 0
      : currentIndexFocus.value + 1
  setFocus()
}
function handleUp() {
  currentIndexFocus.value =
    0 <= currentIndexFocus.value - 1
      ? currentIndexFocus.value - 1
      : result.value.items.length - 1
  setFocus()
}

watch(result, (newVal) => {
  if (newVal?.items?.length) {
    document.body.classList.add('is-active-search')
  } else {
    document.body.classList.remove('is-active-search')
  }
})

const itemsRefs = ref(null)
const currentIndexFocus = ref(-1)

function setFocus() {
  if (currentIndexFocus.value > result.value.items.length - 1) {
    currentIndexFocus.value = 0
  }
  itemsRefs.value[currentIndexFocus.value].focus()
}

const __returned__ = { props, t, FinancingPeriodPlurals, inputRef, isShow, isFocused, searchValue, category, preparedLink, load, loading, result, debounceSearch, getResult, handleInput, handleLoad, handleFocus, handleInputBlur, handleBlur, resetCategory, resetSearch, handleDown, handleUp, itemsRefs, currentIndexFocus, setFocus, computed, ref, watch, get useI18n() { return useI18n }, get useDebounceFn() { return useDebounceFn }, get useFocus() { return useFocus }, get cancellablePromiseProxy() { return cancellablePromiseProxy }, get catalogApi() { return catalogApi }, get pluralization() { return pluralization }, get useLoadable() { return useLoadable } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})