import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterWidgetWrapper',
  props: {
    title: {},
    collapsable: { type: Boolean },
    state: { type: Boolean },
    unit: {}
  },
  emits: ["update:state"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const state = ref(props.state)

function toggleState() {
  state.value =  !state.value
}
function onUpdateState(newState: boolean) {
  state.value = newState
}


const __returned__ = { props, emit, state, toggleState, onUpdateState, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})