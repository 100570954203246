import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "sk-steps-wrap" }
const _hoisted_2 = ["aria-current"]
const _hoisted_3 = { class: "sk-steps__el" }
const _hoisted_4 = {
  key: 0,
  class: "sk-steps__value"
}
const _hoisted_5 = {
  key: 1,
  class: "sk-steps__value"
}
const _hoisted_6 = { class: "sk-steps__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("ul", {
        class: "sk-steps",
        role: "list",
        style: _normalizeStyle({"--step": $setup.config.length})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.config, (step, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: step.name,
            class: _normalizeClass(["sk-steps__item", { "isActive" : $setup.isActive(index), "isDisabled" : $setup.disabled(step), "isDone": index < $setup.currentStep || index <= $setup.currentStep && index === $setup.config.length - 1}]),
            "aria-current": $setup.isActive(index) ? "step" : undefined
          }, [
            _createElementVNode("div", _hoisted_3, [
              (index < $setup.currentStep || index <= $setup.currentStep && index === $setup.config.length - 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
                    _createElementVNode("svg", {
                      width: "16",
                      height: "16",
                      viewBox: "0 0 16 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("path", {
                        d: "M13.3307 4L5.9974 11.3333L2.66406 8",
                        stroke: "white",
                        "stroke-width": "3",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(index+1), 1))
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(step.title), 1)
          ], 10, _hoisted_2))
        }), 128))
      ], 4)
    ]),
    _renderSlot(_ctx.$slots, "default", {
      currentStep: $setup.currentStep,
      nextStep: $setup.nextStep
    })
  ]))
}