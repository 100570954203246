import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading-wrapper", { 'is-loading': $props.loading, 'loading-wrapper--variant_rounded': $props.rounded }])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "loading-wrapper__overlay" }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "loading-wrapper__indicator" }, [
      _createElementVNode("div", { class: "loading-wrap" }, [
        _createElementVNode("div", { class: "loading" })
      ])
    ], -1))
  ], 2))
}