import { defineComponent as _defineComponent } from 'vue'
import { defineAsyncComponent } from 'vue'

import { friendlyUrlParser } from '@/utils/filters'
import CatalogController from './CatalogController.vue'
import { baseResource } from '@/plugins/resource'


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogView',
  props: {
    pagination: {},
    filters: {},
    baseUrl: {},
    resource: {},
    catalogFiltersComponent: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props
function parametersTo({ filters, offset, ...rest }) {
  return {
    ...rest,
    filters: filters || undefined,
  }
}
function parametersFrom({ ...rest }) {
  const { parsedUrl: data, page } = friendlyUrlParser(`${props.baseUrl}`)
  return {
    ...rest,
    page,
    filters: data,
  }
}

function manualHistoryChangeHandler() {
  // if (this.$refs.controller) {
  //   this.$nextTick(this.$refs.controller.search);
  // }
}

const __returned__ = { props, parametersTo, parametersFrom, manualHistoryChangeHandler, defineAsyncComponent, get friendlyUrlParser() { return friendlyUrlParser }, CatalogController, get baseResource() { return baseResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})