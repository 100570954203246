import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import TagsWidget from '@/components/common/catalog/widgets/TagsWidget.vue'
import SelectWidget from '@/components/common/catalog/widgets/SelectWidget.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogCatalogFilters',
  props: {
    filtersList: {},
    parameters: {}
  },
  emits: ["update:parameters"],
  setup(__props: any, { expose: __expose }) {
  __expose();





const classList = {
  category: 'g-cols--narrow-sm g-cols--narrow-md',
  labels: 'g-cols--auto-md',
}

const WIDGETS_MAP = {
  'multi-select': {
    component: TagsWidget,
  },
  select: {
    component: SelectWidget,
  },
} as const

const WIDGETS_MAP_BY_NAME = {
  labels: {
    component: TagsWidget,
  },
  category: {
    component: SelectWidget,
  }
}

const __returned__ = { classList, WIDGETS_MAP, WIDGETS_MAP_BY_NAME, ref, TagsWidget, SelectWidget }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})