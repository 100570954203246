import { defineComponent as _defineComponent } from 'vue'
import { onMounted, watch, ref } from 'vue'
import { allowDateInRange } from '@/utils/helpers'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateRangeWidget',
  props: {
    config: {},
    filter: {},
    available: {},
    availableAttributeValues: {},
    modelValue: {}
  },
  emits: ['change:model-value', 'update:state', 'commit'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const date = ref({
  min: props.modelValue?.min ? new Date(`${props.modelValue?.min}`) : props.available?.min && new Date(`${props.available?.min}`) || null,
  max: props.modelValue?.max ? new Date(`${props.modelValue?.max}`) : props.available?.max && new Date(`${props.available?.max}`) || null,
})

const apply = () => emit('commit')
const update = (value, key) => {
  date.value[key] = value
  emit('change:modelValue', {
    min: date.value?.min ? date.value.min?.getFullYear() : null,
    max: date.value?.max ? date.value.max?.getFullYear() : null,
  })
  apply()
}

watch(() => props.available, () => {
  if (!props.modelValue?.min) {
    date.value.min = new Date(`${props.available?.min}`) || null
  }
  if (!props.modelValue?.max) {
    date.value.max = new Date(`${props.available?.max}`) || null
  }
})

watch(
  () => props.modelValue,
  () => {
    date.value.min = props.modelValue?.min
      ? new Date(`${props.modelValue?.min}`)
      : props.available?.min && new Date(`${props.available?.min}`) || null
    date.value.max = props.modelValue?.max
      ? new Date(`${props.modelValue?.max}`)
      : props.available?.max && new Date(`${props.available?.max}`) || null
  }
)

onMounted(() => {
  emit('update:state', 
    !!(props.modelValue?.min || props.modelValue?.max)
  )
})

const __returned__ = { props, emit, date, apply, update, onMounted, watch, ref, get allowDateInRange() { return allowDateInRange } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})