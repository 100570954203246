import { defineComponent as _defineComponent } from 'vue'
import { computed, watch, toRefs, onMounted, onUnmounted, ref } from 'vue'
import type { Ref } from 'vue'
import VueMultiselect from 'vue-multiselect'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'CSelect',
  props: {
  labelBy: String,
  id: {
    type: String,
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  innerElement: {
    type: String,
    default: 'input',
  },
  blockName: {
    type: String,
    default: 'control-input',
  },
  variant: {
    type: Array,
    default: () => [],
  },
  size: {
    type: Array,
    default: () => ['md', 'md-xl'],
  },
  styling: {
    type: String,
    default: 'default',
  },
  appearance: {
    type: Array,
    default: () => [],
  },
  view: {
    type: String,
    default: '',
  },
  iconSize: {
    type: [String, Array],
    default: '2xl',
  },
  startIcon: {
    type: String,
    default: '',
  },
  endIcon: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Number, Object, Array],
    default: '',
  },
  invalid: Boolean,
},
  emits: 'updateModel',
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();



const emit = __emit

const props = __props

const blockClasses = computed(() => {
  type Classes = Record<string, boolean | string>
  const sizesClass: Classes = props.size.reduce(
    (acc: Classes, size: string) => {
      acc[`${props.blockName}--size_${size}`] = true
      return acc
    },
    {} as Classes
  )
  const appearanceClass: Classes = props.appearance.reduce(
    (acc: Classes, appearance: string) => {
      acc[`${props.blockName}--appearance_${appearance}`] = true
      return acc
    },
    {} as Classes
  )
  const variantClass: Classes = props.variant.reduce(
    (acc: Classes, variant: string) => {
      acc[`${props.blockName}--variant_${variant}`] = true
      return acc
    },
    {} as Classes
  )
  return {
    [`${props.blockName}`]: true,
    [`${props.blockName}--styling_${props.styling}`]: props.styling,
    ...sizesClass,
    ...appearanceClass,
    ...variantClass,
    [`${props.blockName}--view_${props.view}`]: props.view,
    [`is-disabled`]: props.disabled,
    [`is-readonly`]: props.readonly,
    [`is-invalid`]: props.invalid,
  }
})

const defaultOptions = ref({
  tagPlaceholder: '',
  placeholder: '',
  showLabels: false,
  closeOnSelect: true,
  searchable: false,
})

async function visibilityChanged ([{ isIntersecting }]) {
  if(isIntersecting) {
    await emit('getItems')
  }
}

const __returned__ = { emit, props, blockClasses, defaultOptions, visibilityChanged, computed, watch, toRefs, onMounted, onUnmounted, ref, get VueMultiselect() { return VueMultiselect } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})