import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import sentryInit from './sentry'
import { TEST_DOMAINS } from '@/const'
import { createApplication } from '@/app'

import 'vue-final-modal/style.css'
import 'floating-vue/dist/style.css'
// import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-toast-notification/dist/theme-default.css'

import 'maz-ui/css/main.css'
import 'vue-slider-component/theme/default.css'

import './styles/index.sass'
import '@/utils/ui'

export const app = createApplication({
  createApp,
  createRouter,
  createWebHistory,
  createPinia,
})
if (window.LOG_SENTRY_ERRORS) {
  sentryInit()
}

app.mount('#app')

window.app = app

declare global {
  interface Window {
    IS_AUTH: number | false
    IS_PUBLIC: boolean
    IS_SUPER: number | false
    ACCESS_TOKENS: string[]
    ALL_ACCESS_TOKENS: Record<string, any>
    REMOTE_CONFIG: Record<string, any>
    LOG_SENTRY_ERRORS: boolean
    SENTRY_DSN: string
    // USER_PERMISSIONS: any
    // ALL_PERMISSIONS: any
    google: {
      maps: any
    }
    initMap: () => void
    language: string
    site_title: string
    show_site_title: boolean
    default_language: string
    current_language: string
    country_code: string
    dataLayer: { 
      event: string
      [key: string]: string | number
    }[]

    django: {
      catalog: {
        [key: string]: string
      }
    }
    app: App
    captcha_key: string
    login_url: string
    comparisons_url: string
    favicon_url: string
    favorites_url: string
    comparison_url: string
    applying_for_financing_url: string
    user_agreement: string
    owner_catalog_url: string
    privacy_policy: string
    maps_key: string
    logo_cabinet: string
    currentLocale: string
    organization_country_code: string
  }
}
