import { defineComponent as _defineComponent } from 'vue'
import VueMultiselect from "vue-multiselect"
import { ref, watch, useSlots, computed } from 'vue'


const r = 'required'


export default /*@__PURE__*/_defineComponent({
  __name: 'multiselect',
  props: {
  errors: {
    type: Object,
    default: () => ({}),
  },
  disabled: Boolean,
  isSuccess: Boolean,
  inputLabel: String,
  staticLabel: String,
  options: Array,
  labelBy: String,
  trackBy: String,
  allowEmpty: Boolean,
  placeholder: String,
  pagination: Object,
  showObserveVisibility: Boolean,
  value: Object,
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: String,
    default: '',
  },
  setValue: {},
},
  emits: ['getItems', 'clearMultiselect'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

const eSlots = computed(() => {
  return getESlots([...Object.keys(useSlots())])
})

const props = __props

const model = ref(props.value)

watch(() => props.value, () => model.value = props.value)

const isRequired = props.rules.includes(r)
const isValid = ref(null)

const emit = __emit

async function visibilityChanged (isVisible) {
  if( isVisible ) {
    await emit('getItems')
  }
}



const __returned__ = { eSlotsResolver, getESlots, eSlots, props, r, model, isRequired, isValid, emit, visibilityChanged, get VueMultiselect() { return VueMultiselect }, ref, watch, useSlots, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})