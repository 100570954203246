import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiLightbox',
  props: {
    items: { default: () => [] }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const index = ref<number | null>(null)
const silentboxRef = ref(null)
const visible = ref<boolean>(false)

function openImg(idx: number) {
  visible.value = true
  silentboxRef.value.openOverlay(idx)
}

function handleHide() {
  visible.value = false
}

const __returned__ = { props, index, silentboxRef, visible, openImg, handleHide, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})