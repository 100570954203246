import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import SelectWidget from '@/components/common/catalog/widgets/SelectWidget.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'VacanciesCatalogFilters',
  props: {
    filtersList: {},
    parameters: {}
  },
  emits: ["update:parameters", "reset:parameters"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const emit = __emit

const WIDGETS_MAP = {
  select: {
    component: SelectWidget,
  },
} as const
const WIDGETS_DEPENDENCY_MAP = {
  // 'direction'
  // 'position'
  // 'country'
  'city':  'country',
  // 'work_location'
  // 'employment_type'

} as const


const isOpen = ref(false)

function openFilterModal() {
  isOpen.value = true
}
function resetFilters() {
  emit('reset:parameters', {})
}
const isDesktop = ref(true)
function checkWidth() {
  isDesktop.value = window.innerWidth >= 1200
}
onMounted(() => {
  checkWidth()
})

const __returned__ = { emit, WIDGETS_MAP, WIDGETS_DEPENDENCY_MAP, isOpen, openFilterModal, resetFilters, isDesktop, checkWidth, ref, onMounted, get VueFinalModal() { return VueFinalModal }, SelectWidget }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})