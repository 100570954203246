import { defineComponent as _defineComponent } from 'vue'
import { onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMessageModal',
  props: {
  message: {
    type: Object,
    default: () => ({}),
  },
  redirect: {
    type: Object,
    default: null,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const router = useRouter()

onBeforeUnmount(() => {
  if (props.redirect?.location) window.location.href = props.redirect?.location
  if (props.redirect?.to) {
    router.push(props.redirect.to)
  }
})

const __returned__ = { props, router, onBeforeUnmount, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})