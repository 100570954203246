import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "catalog-sidebar" }
const _hoisted_2 = { class: "catalog-sidebar__mobile-triggers" }
const _hoisted_3 = { class: "ds-caption ds-caption--appearance_medium ds-caption--color_accent" }
const _hoisted_4 = { class: "g-row g-row--align_center" }
const _hoisted_5 = { class: "g-cell g-cols" }
const _hoisted_6 = { class: "g-cell g-cols" }
const _hoisted_7 = { class: "ds-caption ds-caption--appearance_medium ds-caption--size_xs" }
const _hoisted_8 = { class: "catalog-sidebar__filters" }
const _hoisted_9 = { class: "main-title main-title--variant_modal" }
const _hoisted_10 = { class: "catalog-sidebar__list catalog-sidebar__list--tiny" }
const _hoisted_11 = { class: "catalog-sidebar__btn" }
const _hoisted_12 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ControlButton = _resolveComponent("ControlButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("button", {
          class: "ds-link ds-link--styling_inherit",
          type: "button",
          onClick: $setup.openFilterModal
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_AppIcon, {
                name: "i-document-filter",
                size: "xl"
              })
            ]),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("Filter")), 1)
          ])
        ])
      ]),
      _createElementVNode("button", {
        class: "ds-link ds-link--styling_primary",
        type: "button",
        onClick: $setup.resetFilters
      }, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("clear")), 1)
      ])
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.isDesktop ? 'div' : $setup.VueFinalModal), {
      "content-class": "filter-modal",
      "modal-id": 'filtersmodal',
      modelValue: $setup.isOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.isOpen) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("Filters")), 1),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filtersList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "catalog-sidebar__item",
                key: item.name
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent($setup.WIDGETS_MAP[item.type].component), _mergeProps({ ref_for: true }, item, {
                  onInput: ($event: any) => (_ctx.$emit('update:parameters', { [item.name]: $event })),
                  value: $props.parameters && $props.parameters[item.name],
                  "dependency-key": $setup.WIDGETS_DEPENDENCY_MAP[item.name],
                  "dependency-value": $setup.WIDGETS_DEPENDENCY_MAP[item.name] && $props.parameters && $props.parameters[$setup.WIDGETS_DEPENDENCY_MAP[item.name]]
                }), null, 16, ["onInput", "value", "dependency-key", "dependency-value"]))
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_ControlButton, {
              styling: "primary",
              view: "full-till-sm",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isOpen=false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Apply filters")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("button", {
            class: "modal-close-button",
            type: "button",
            "aria-label": _ctx.$t('Close'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.isOpen = false))
          }, [
            _createVNode(_component_AppIcon, {
              "aria-hidden": "true",
              name: "i-close",
              size: ['sm', 'lg-xl']
            })
          ], 8, _hoisted_12)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]))
  ]))
}