/* eslint-disable */

import { i18n } from '@/i18n'
// import { isValidPhoneNumber } from 'libphonenumber-js'

const { t: $t, d: $d, n: $n } = i18n.global

export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0
}

export function getSingleParam<TParam = unknown>(
  params: [TParam] | Record<string, TParam>,
  paramName: string
) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

function vin(this:any, value: any) {
  if (!value || !value.length) {
    return true
  }

  // const VIN_REG = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/
  const VIN_REG = /^(?<wmi>[A-HJ-NPR-Z\d]{3})(?<vds>[A-HJ-NPR-Z\d]{5})(?<check>[\dX])(?<vis>(?<year>[A-HJ-NPR-Z\d])(?<plant>[A-HJ-NPR-Z\d])(?<seq>[A-HJ-NPR-Z\d]{6}))$/
  if (!VIN_REG.test(value.trim())) {
    return this.config.globalProperties.$t('You need to enter a valid vin')
  }
  return true
}

function address(this: any, value: any) {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return this.config.globalProperties.$t('This field is required')
  }
  if (!value?.address || !value.lat || !value.lng) {
    return this.config.globalProperties.$t('This field is required')
  }
  return true
}

function required(this: any, value: any) {
  if (
    isNullOrUndefined(value) ||
    isEmptyArray(value) ||
    value === false ||
    value === ''
  ) {
    return $t('This field is required')
  }
  return true
}

function email(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return $t('This field must be a valid email')
  }
  return true
}

function confirmed(this: any, value: any, [target]: string, rest) {
  if (value === target) {
    return true
  }
  if('newPassword1' === rest.field) {
    return $t('Password did not match')
  }
  return $t('Field did not match')
}

function min(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every((val) => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    return `${$t('Min')}:${length}`
  }
}
const max = (
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every((val) => max(val, { length }))
  }

  return [...String(value)].length <= Number(length)
}
function maxArrLength(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.length <= Number(length) || `${$t('Maximum')}: ${length}`
  } else {
    return `${$t('Maximum')}:${length}`
  }
}

function decimal(
  this: any,
  value: string,
  params: [string | number] | { length: string | number }
): boolean | string {
  const separator = '.'
  if (isEmpty(value)) {
    return true
  }
  const length = getSingleParam(params, 'length')
  const errorText = `${$t(
    'Ensure that the decimal places do not exceed'
  )} ${length}`
  if (isNullOrUndefined(value) || '' === value) {
    return errorText
  }

  if (Array.isArray(value)) {
    return value.every((val) => decimal(val, { length }))
  }

  // if is 0.
  if (0 === Number(length)) {
    return /^-?\d*$/.test(value) || errorText
  }
  const regexPart = '*' === length ? '+' : `{1,${length}}`
  const regex = new RegExp(
    `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
  )

  if (!regex.test(value)) {
    return errorText
  }

  const parsedValue = parseFloat(value)

  // eslint-disable-next-line
  return parsedValue === parsedValue || errorText
}

function minArrLength(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  const length = getSingleParam(params, 'length')
  if (isEmpty(value)) {
    return `${$t('Minimum')}: ${length}`
  }

  if (Array.isArray(value)) {
    return value.length >= Number(length) || `${$t('Minimum')}: ${length}`
  } else {
    return `${$t('Minimum')}: ${length}`
  }
}

function maxValue(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (Number(value) <= Number(length)) {
    return Number(value) <= Number(length)
  } else {
    return `${$t('Max')}: ${length}`
  }
}

function minValue(
  this: any,
  value: unknown,
  params: [string | number] | { length: string | number }
): boolean | string {
  if (!value) {
    return true
  }
  const length = getSingleParam(params, 'length')
  if (Number(value) >= Number(length)) {
    return Number(value) >= Number(length)
  } else {
    return `${$t('Min')}: ${length}`
  }
}

function url(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }

  const URLREG =
    /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
  if (!URLREG.test(value)) {
    return $t('This field must be a valid url')
  }
  return true
}

function postBackUrl(this: any, value: any) {
  if (!value || !value.length) {
    return true
  }
  const URLREG = /^(https?|chrome):\/\/[^\s$.?#].[^\s].+\?*={click_id}.*$/
  if (!URLREG.test(value.replace('%7B', '{').replace('%7D', '}'))) {
    return $t('This field must be a valid url')
  }

  return true
}
export const MOBILEREG = /^[+()-\d]+$/

export const EMAILREG =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function emailOrPhone(this: any, value: any) {
  return (
    EMAILREG.test(value) ||
    MOBILEREG.test(value) ||
    $t('Set correct email or phone')
  )
}

async function realPhone(this: any, value: any, [realValue]: string) {
  const { isValidPhoneNumber } = await import('libphonenumber-js')
  return isValidPhoneNumber(realValue) || $t('Phone is not valid')
}

function multipleOf(this: any, value: any, [num]: string) {
  return value % Number(num) === 0 || $t('Amount must be a multiple of') + ' ' + Number(num)
}

export {
  url,
  required,
  address,
  vin,
  email,
  confirmed,
  min,
  max,
  decimal,
  maxArrLength,
  minArrLength,
  maxValue,
  minValue,
  postBackUrl,
  emailOrPhone,
  realPhone,
  multipleOf,
}
