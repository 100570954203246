import { defineComponent as _defineComponent } from 'vue'
import { ajaxOwnerCatalogList } from '@services/catalog.service'
import UrlParametersController from './UrlParametersController'


export default /*@__PURE__*/_defineComponent({
  __name: 'OwnerCatalogView',
  props: {
    pagination: {},
    category: {},
    filters: {},
    baseUrl: {},
    owner: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const mainAttributesLabels = [
    'category',
    'filters',
    'locationCountries',
    'categories',
    'brands',
    'brand_models',
    'brands_models',
]

const props = __props
function parametersTo(params: { filters, offset }) {
  return params
  // return {
  //   ...rest,
  //   filters: filters || undefined,
  // }
}
function parametersFrom({ ...rest }) {
  // const { parsedUrl: data, page } = friendlyUrlParser(`${props.baseUrl}filters/`)
  // const data = friendlyUrlParser()
  const data = {}
  const page = 1
  return {
    ...rest,
    page,
    filters: data,
  }
}

function manualHistoryChangeHandler() {
  // if (this.$refs.controller) {
  //   this.$nextTick(this.$refs.controller.search);
  // }
}

const __returned__ = { mainAttributesLabels, props, parametersTo, parametersFrom, manualHistoryChangeHandler, get ajaxOwnerCatalogList() { return ajaxOwnerCatalogList }, get UrlParametersController() { return UrlParametersController } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})