import { defineComponent as _defineComponent } from 'vue'
import { computed, toRefs } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'CLabel',
  props: {
  styling: {
    type: String,
    default: 'default',
  },
  isRequired: Boolean,
  staticLabel: {
    type: Boolean,
    default: true,
  },
  inputLabel: {
    type: String,
    required: false,
    default: null,
  },
  hint: String,
  id: String,
  value: [Array, String, Boolean, Object],
  modelValue: [Array, String, Boolean, Object],
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const isValue = computed(() => {
  const { modelValue, value } = toRefs(props)
  if (modelValue.value || value.value) {
    return check(modelValue.value || value.value)
  }
  return false
})

const check = (val: string | boolean | unknown[] | Record<string, any> | undefined): boolean => {
  if ('boolean' === typeof val) {
    return val
  }
  if (Array.isArray(val) && val.length || val && val.length || val) {
    return true
  }
  return false
}

const __returned__ = { props, isValue, check, computed, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})