import { defineComponent as _defineComponent } from 'vue'
import {
  computed,
  // defineComponent,
  // defineAsyncComponent,
  onMounted,
  // reactive,
  markRaw,
  ref,
  type Ref,
  watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy'
import {
  useGenerator,
  type GeneratorConfig,
  type WidgetsMap,
} from '@packages/vue-filters-generator'
import { pluralization } from '@/utils/intl-helpers'
import { catalogApi } from '@/services/catalog.service'
import { useMainPageCategoryStore } from './store'
import type { Category } from './types'
import { useLoadable } from '@/packages/vue-loading-state'
import { urlGetter } from '@/components/offersCatalog/utils'
import { transform, prepare } from './utils'

import { defaultDatePickerConfig } from '@/components/offersCatalog/widgets/index'

import SelectWidget from '@/components/offersCatalog/widgets/SelectWidget.vue'
import DataRangeWidget from '@/components/offersCatalog/widgets/DateRangeWidget.vue'
import InputRangeWidget from '@/components/offersCatalog/widgets/InputRangeWidget.vue'
import RangeWidget from '@/components/offersCatalog/widgets/RangeWidget.vue'
import BrandsModelsWidget from '@/components/offersCatalog/widgets/BrandsModelsWidget.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainPageFilters',
  props: {
    initialCategory: {},
    baseCatalogUrl: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const WIDGETS_MAP = {
  'brands-models': {
    component: markRaw(BrandsModelsWidget),
    config: {
      canAddMore: false,
    },
    // config: {...defaultMultiselectConfig},
  },
  multiselect: {
    component: markRaw(SelectWidget),
    config: {},
  },
  // 'select': {
  //   component: markRaw(CheckboxWidget),
  //   // config: {...defaultMultiselectConfig},
  // },
  'input-range': {
    component: markRaw(InputRangeWidget),
    config: {},
  },
  range: {
    component: markRaw(RangeWidget),
    config: {},
  },
  'date-range': {
    component: markRaw(DataRangeWidget),
    config: {
      ...defaultDatePickerConfig,
    },
  },
}

const props = __props

const isInitialized = ref(false)
const isInitializedAvailable = ref(false)

const displayedFilters = [
  'categories',
  'locationCountries',
  'brands',
  'registration',
  'price',
  'downPayment',
]

const { t } = useI18n()

const tokenString = ref('')
const catalogUrl: Ref<string> = ref('')
const mainPageCategoryStore = useMainPageCategoryStore()
const { category: activeCategory } = storeToRefs(mainPageCategoryStore)

const { load, loading, result } = useLoadable(getFiltersView)
const {
  load: loadAvailable,
  loading: loadingAvailable,
  result: available,
} = useLoadable(getAvailable)
const {
  load: loadTotalOffers,
  loading: loadingTotal,
  result: totalOffers,
} = useLoadable(getResult)

const nodes = ref([])
const lastModifiedNodes = ref(Date.now())
const value: Ref<{ [key: string]: any }> = ref({})
const dummyAvailable: Ref<any[]> = ref([])

watch(
  activeCategory,
  (newVal: Category) => {
    if (newVal) {
      value.value = {}
      load([{}, { category: newVal.id }])
        .catch((e) => {
          console.warn('catch', e)
          return e
        })
        .then(() => {
          initGenerator()
          onApply({})
        })
    }
  },
  {
    immediate: true,
  }
)
onMounted(() => {
  if (props.initialCategory?.slug)
    load([{}, { category: props.initialCategory?.id }])
      .catch((e) => {
        console.warn('catch', e)
        return e
      })
      .then(() => {
        initGenerator()
        onApply({})
      })
})

const searchResultBtnText = computed(() => {
  return totalOffers.value || 0
})

function initGenerator() {
  if (result.value) {
    const generatorConfig = {
      widgetsMap: WIDGETS_MAP,
      filters: result as any,
      onApply,
      available: dummyAvailable as any,
      typeGetter: (el) => el.widgetType,
      keyGetter: (el) => el.slug,
    }
    const {
      keyGetter,
      typeGetter,
      nodes: localNodes,
    } = useGenerator(generatorConfig, value)
    nodes.value = localNodes.value as any
    lastModifiedNodes.value = Date.now()
  }
}

function onApply(val) {
  const mainAttributesLabels = [
    'category',
    'filters',
    'locationCountries',
    'categories',
    'brands',
    'brand_models',
    'brands_models',
  ]
  const transformed = transform(result.value || [], val)
  const prepared = prepare(transformed, mainAttributesLabels)
  const order = {
    path: [
      'prefix',
      'locationCountries',
      'categories',
      'brands',
      'brand_models',
      'brands_models',
      'filters',
      'page',
    ],
    query: ['sort'],
  }
  tokenString.value = urlGetter(
    [`/${activeCategory.value?.slug || props.initialCategory?.slug}/`],
    { path: order.path },
    { ...prepared, page: undefined }
  )
  const [baseUrl] = props.baseCatalogUrl.split('dummy/')
  catalogUrl.value = urlGetter(
    // Object.keys(params.filters || {}).length
    //   ? [props.baseUrl, 'filters']
    //   : [props.baseUrl],
    [
      `${baseUrl}/${
        activeCategory.value?.slug || props.initialCategory?.slug
      }/`,
    ],
    order,
    { ...prepared }
  )
  loadAvailable([{ token_string: tokenString.value }])
  loadTotalOffers([{ token_string: tokenString.value, limit: 1 }])
}

function getFiltersView(args) {
  const cancelController = new AbortController()
  const resource = catalogApi.offers.filtersView.config(
    'signal',
    cancelController.signal
  )
  const request = cancellablePromiseProxy(
    new Promise((resolve, reject) => {
      resource.execute(...args).then(resolve, reject)
    }),
    { cancelController }
  )
    .then((res) => res.data?.item)
    .catch((err) => (err.name == 'AbortError' ? [] : err))
    .finally(() => {
      isInitialized.value = true
    })
  return request
}
function getAvailable(args) {
  const cancelController = new AbortController()
  const resource = catalogApi.offers.filtersAvailable.config(
    'signal',
    cancelController.signal
  )
  const request = cancellablePromiseProxy(
    new Promise((resolve, reject) => {
      resource.execute(...args).then(resolve, reject)
    }),
    { cancelController }
  )
    .then((res) => res?.available)
    .catch((err) => (err.name == 'AbortError' ? [] : err))
    .finally(() => {
      isInitializedAvailable.value = true
    })

  return request
}
function getResult(args) {
  const cancelController = new AbortController()
  const resource = catalogApi.offers.list.config(
    'signal',
    cancelController.signal
  )
  const request = cancellablePromiseProxy(
    new Promise((resolve, reject) => {
      resource.execute(...args).then(resolve, reject)
    }),
    { cancelController }
  )
    .then((res) => res?.pagination?.totalResult || res?.pagination?.total)
    .catch((err) => (err.name == 'AbortError' ? [] : err))

  return request
}
function clearFilters() {
  value.value = {}
  // lastModifiedNodes
  onApply({})
}

const __returned__ = { WIDGETS_MAP, props, isInitialized, isInitializedAvailable, displayedFilters, t, tokenString, catalogUrl, mainPageCategoryStore, activeCategory, load, loading, result, loadAvailable, loadingAvailable, available, loadTotalOffers, loadingTotal, totalOffers, nodes, lastModifiedNodes, value, dummyAvailable, searchResultBtnText, initGenerator, onApply, getFiltersView, getAvailable, getResult, clearFilters, computed, onMounted, markRaw, ref, watch, get useI18n() { return useI18n }, get storeToRefs() { return storeToRefs }, get cancellablePromiseProxy() { return cancellablePromiseProxy }, get useGenerator() { return useGenerator }, get pluralization() { return pluralization }, get catalogApi() { return catalogApi }, get useMainPageCategoryStore() { return useMainPageCategoryStore }, get useLoadable() { return useLoadable }, get urlGetter() { return urlGetter }, get transform() { return transform }, get prepare() { return prepare }, get defaultDatePickerConfig() { return defaultDatePickerConfig }, SelectWidget, DataRangeWidget, InputRangeWidget, RangeWidget, BrandsModelsWidget }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})