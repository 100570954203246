import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, nextTick, watch, ref } from 'vue'
import { clone, omit } from 'ramda'
// import { useDebounceFn } from '@vueuse/core'
import BrandModelPair from './BrandModelPair.vue'

const LIMIT_FOR_SEARCH = 20


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'BrandsModelsWidget',
  props: {
    filter: {},
    config: {},
    available: {},
    availableModels: {},
    modelValue: {},
    isHideUnavailable: { type: Boolean }
  },
  emits: ["commit", "update:model-value", "change:model-value"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const props = __props

const emit = __emit

function apply() {
  emit('commit')
}

const internal = ref([
  {
    brand: null,
    model: null,
    key: 'one',
  },
])

const selectedModelsIds = computed(() =>
  internal.value.map((el) => el.model?.id).filter(Boolean)
)

const filteredChoices = computed(() => {
  return props.filter.choices.map((brand) => {
    const b = JSON.parse(JSON.stringify(brand))
    b.models = (b?.models || []).filter(
      (model) => !selectedModelsIds.value.includes(model.id)
    )
    return b
  })
})

const modelKey = ref(1)

onMounted(() => {
  if (props.modelValue?.length) {
    internal.value = props.modelValue.map((el) => {
      el.key = ++modelKey.value
      return el
    })
  }
  const [first] = internal.value
  emit('update:state', !!first.brand)
})

const isInitialized = ref(false)

watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      if (!isInitialized.value && newVal?.length) {
        internal.value = newVal.map((el) => {
          el.key = ++modelKey.value
          return el
        })
        isInitialized.value = true
      }
    }
    if (newVal?.length) {
      if (newVal.length === internal.value.length) {
        const temp = internal.value.filter((el) => {
          if (!el.brand && !el.model) return true
          return newVal.some((val) => {
            return null === el.model
              ? val.brand?.id === el.brand?.id && null === val.model
              : val.brand?.id === el.brand?.id && val.model.id === el.model.id
          })
        })
        internal.value = temp
      } else {
        internal.value = newVal.map((el) => {
          el.key = ++modelKey.value
          return el
        })
      }
    } else {
      internal.value = [
        {
          brand: null,
          model: null,
          key: ++modelKey.value,
        },
      ]
    }
  },
  {
    deep: true,
  }
)

const hasAnyEmpty = computed(() => {
  return !!internal.value.find((el) => {
    return null == el.brand && null === el.model
  })
})

function addPair() {
  internal.value.push({
    brand: null,
    model: null,
  })
}
function deletePair(index: number) {
  internal.value.splice(index, 1)
  nextTick(() => {
    emit(
      'change:model-value',
      internal.value
        .filter((el) => !el.hasError)
        .filter((el) => el.brand)
        .map((el) => omit(['slug', 'order', 'hasError'], el))
    )
    apply()
  })
}

const selectedBrandIds = computed(() =>
  [...internal.value].map((el) => el?.brand?.id).filter(Boolean)
)

function onUpdate(value, index) {
  clone(value)

  const hasConflictError = selectedBrandIds.value.includes(value?.brand?.id)
  const temp = clone(value)
  temp.hasError = !value.model && hasConflictError

  if (value.brand?.slug) {
    temp.slug = `${value.brand.slug}`
  }
  if (value.model?.slug) {
    temp.slug += `_${value.model.slug}`
  }
  internal.value[index] = temp
  if (!temp.hasError) {
    emit(
      'change:model-value',
      internal.value
        .filter((el) => !el.hasError)
        .filter((el) => el.brand)
        .map((el) => omit(['slug', 'order', 'hasError'], el))
    )
    apply()
  }
}

const __returned__ = { LIMIT_FOR_SEARCH, props, emit, apply, internal, selectedModelsIds, filteredChoices, modelKey, isInitialized, hasAnyEmpty, addPair, deletePair, selectedBrandIds, onUpdate, computed, onMounted, nextTick, watch, ref, get clone() { return clone }, get omit() { return omit }, BrandModelPair }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})