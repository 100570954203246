import { defineComponent as _defineComponent } from 'vue'
import { ref, toRefs, type Ref } from 'vue'

const UPDATE_ACTIVE_STEP_EVENT = 'update:activeStep'
const CHANGE_STEP_EVENT = 'step-change'


export default /*@__PURE__*/_defineComponent({
  __name: 'StepsController',
  props: {
    config: {},
    step: {},
    beforeNext: { type: Function }
  },
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit

const currentStep: Ref<number> = ref(props.step || 0)
const { config, beforeNext } = toRefs(props)

const onItemClick = (event, item, index) => {
  if (disabled(item)) {
    event.preventDefault()

    return
  }

  if (item.command) {
    item.command({
      originalEvent: event,
      item: item,
    })
  }

  if (index !== currentStep.value) {
    nextStep(index)
  }

  emit(CHANGE_STEP_EVENT, {
    originalEvent: event,
    index: index,
  })
}

const disabled = (item) => {
  return typeof item.disabled === 'function' ? item.disabled() : item.disabled
}

const prevStep = () => {
  currentStep.value -= 1
  collectValidSteps(currentStep.value)
  emit(UPDATE_ACTIVE_STEP_EVENT, currentStep.value)
}

const nextStep = (step: number) => {
  if (currentStep.value === config.value.length - 1) {
    return
  }

  if (step && step > currentStep.value) {
    currentStep.value = step
    collectValidSteps(currentStep.value)
    emit(UPDATE_ACTIVE_STEP_EVENT, currentStep.value)
  }

}

const collectValidSteps = (step) => {
  config.value.forEach((item, index) => {
    if (index < step) {
      item.inWork = false
    }
    if (index === step) {
      item.inWork = true
      item.disabled = false
    }
    if (index > step) {
      item.inWork = false
      item.disabled = true
    }
  })
}

const isActive = (index: number): boolean => {
  return index === currentStep.value
}

__expose({
  nextStep,
  isActive,
  onItemClick,
  currentStep,
  prevStep,
})

const __returned__ = { props, UPDATE_ACTIVE_STEP_EVENT, CHANGE_STEP_EVENT, emit, currentStep, config, beforeNext, onItemClick, disabled, prevStep, nextStep, collectValidSteps, isActive, ref, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})