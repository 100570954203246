import { defineComponent as _defineComponent } from 'vue'
import { defineAsyncComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { composeTransformers, simplifiers } from '@/utils/transformers'
import { useModalOpener } from '@/composables/useModalOpener'
import { useDropZone, useFileDialog } from '@vueuse/core'
import { fileUploadResource } from '@/services/files.service'
import { useSelectsDynamicOptions } from '@/composables/useSelectsDynamicOptions'
import { nestedValidatorErrorsParser, useSubmit } from '@/composables/useSubmit'
import { vacanciesApi } from '@/services/vacancies.service'
import { geoApi } from '@/services/geo.service'
import { GtagEvents, MAXIMUM_SIZE_FILE_VACANCY } from '@/const'
import {
  ProgressDescriptor,
  filelistToArray,
} from '@/modules/cabinet/modules/Offers/components/file'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'VacancyResponseCreate',
  props: {
    close: { type: Function },
    vacancy: {},
    firstName: {},
    lastName: {},
    email: {},
    phone: {},
    country: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const UiMessageModal = defineAsyncComponent(
  () => import('@/components/ui/modals/UiMessageModal.vue')
)

const transformFrom = composeTransformers(
  simplifiers.same('vacancy'),
  simplifiers.same('pageUrl'),
  simplifiers.same('recaptcha'),
  simplifiers.same('firstName'),
  simplifiers.same('lastName'),
  simplifiers.selectUuid('country'),
  simplifiers.same('location'),
  simplifiers.same('email'),
  simplifiers.same('phone'),
  simplifiers.same('message'),
  simplifiers.same('resume'),
  simplifiers.multiselectValue('socialLinks')
)



const props = __props

const { t } = useI18n()
const currentCountryCode = window.country_code

const captchaRef = ref('')

const saveCaptcha = async (val, setErrors) => {
  form.value.recaptcha = val
  await request(form.value, { setErrors })
}

const isLoad = ref(false)

const getCaptcha = async () => {
  await captchaRef.value.execute()
}

const validateForm = async (f, action, setErrors) => {
  const { valid } = await f()
  if (valid) {
    try {
      await action()
    } catch (e) {
      console.warn(e)
    }
  }
}

const keyForReload = ref(Date.now())
const formRef = ref()
const form = ref({
  vacancy: props.vacancy,
  pageUrl: window.location.toString(),
  firstName: props.firstName || '',
  lastName: props.lastName || '',
  email: props.email || '',
  phone: props.phone || '',
  country: props.country || null,
  location: {
    address: '',
    lat: 0,
    lng: 0,
  },
  message: '',
  resume: null,
  socialLinks: [{ value: '', orderModel: 0 }],
  recaptcha: undefined,
})

const userAgreement = window.user_agreement
const privacyPolicy = window.privacy_policy

const labels = ref({
  firstName: t('First name'),
  lastName: t('Last name'),
  email: t('Email'),
  phone: t('Phone'),
  country: t('Country'),
  location: t('City'),
  message: t('Your message'),
  vacancy: t('Vacancy'),
})

const request = (sendData, control) => {
  isLoad.value = true
  const data = transformFrom(sendData)
  return useSubmit(send(data), control, nestedValidatorErrorsParser)
}

const send = async (sendData) => {
  const resource = props.vacancy ? vacanciesApi.responseVacancy : vacanciesApi.requestVacancy
  try {
    const {
      data: {
        item,
        messages: [message],
      },
    } = await resource.execute(
      {},
      {
        ...sendData,
      }
    )
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: GtagEvents.job_application,
      email: form.value.email,
    })
    props?.close?.()
    const { open } = useModalOpener({
      name: 'UiMessageModal',
      component: UiMessageModal,
      message,
    })
    captchaRef.value?.reset?.()
    open()
    form.value.phone = undefined
    keyForReload.value = Date.now()
    form.value.phone = undefined
    form.value.socialLinks = [
      { value: '', orderModel: socialOrderRender.value++ },
    ]
    fileTemp.value = null
    formRef.value.resetForm()
    keyForReload.value = Date.now()
    isLoad.value = false
    return item
  } catch (e) {
    isLoad.value = false
    captchaRef.value?.reset?.()
    return Promise.reject(e)
  }
}

// dynamic options for selects
const {
  loading: countriesLoading,
  list: countriesList,
  load: countriesLoad,
  debounceSearch: countriesSearch,
  get: countriesGet,
} = useSelectsDynamicOptions(geoApi.countriesList)
countriesGet()

// socials
const socialOrderRender = ref(1)

function deleteSocial(index: number, id?: number) {
  form.value.socialLinks.splice(index, 1)
}

function addSocialNetwork() {
  if (Array.isArray(form.value.socialLinks)) {
    form.value.socialLinks.push({
      value: '',
      orderModel: socialOrderRender.value++,
    })
  } else {
    form.value.socialLinks = [
      {
        value: '',
        orderModel: socialOrderRender.value++,
      },
    ]
  }
}

// file upload

const allowedFiles = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

const {
  files: dialogFiles,
  open: openFileDialog,
  reset,
  onChange,
} = useFileDialog({
  accept: allowedFiles,
  multiple: true,
})

onChange((files) => {
  onDrop(filelistToArray(files))
})

const dropZoneRef = ref<HTMLElement>()
const { isOverDropZone } = useDropZone(dropZoneRef, {
  dataTypes: allowedFiles,
  onDrop,
})

const fileTemp = ref(null)
const fileProgress = ref(null)

function onDrop(files: File[] | null) {
  const [a, r] = partition(acceptor, files)
  const [first] = a || []
  if (first) {
    fileTemp.value = { file: first, name: first.name, progress: 0 }
    upload(fileTemp.value)
  }

  // imageFilesData.value = []
  // if (files) {
  //   imageFilesData.value = files.map((file) => ({
  //     name: file.name,
  //     size: file.size,
  //     type: file.type,
  //     lastModified: file.lastModified,
  //   }))
  // }
}
function partition(
  isValid: (f: File) => boolean,
  array: File[]
): [File[], File[]] {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]
    },
    [[], []]
  )
}
const errors = ref({})
const acceptor = (file: File): boolean => {
  const BYTE = 1024
  if (100 < file.name.length) {
    errors.value[file.name] = t(
      'Make sure the file name is less than 100 characters'
    )
    return false
  }
  if (file.size > MAXIMUM_SIZE_FILE_VACANCY) {
    errors.value[file.name] =
      // eslint-disable-next-line prefer-template
      t('File') +
      ' "' +
      file.name +
      '" ' +
      t('size must be less than') +
      ' ' +
      MAXIMUM_SIZE_FILE_VACANCY / BYTE / BYTE +
      ' ' +
      t('MB')
    return false
  }
  return true
}
function upload(file: { file: File; name: string }) {
  const proposal = fileUploadResource
    .execute(
      Object.assign({}),
      Object.assign({ file: file.file, title: file.name })
    )
    .then((result) => {
      form.value.resume = result?.item?.id
    })
    .catch((result) => {
      console.warn(result)
    })

  // accepted.value[index].status = PROGRESS
  fileProgress.value = proposal

  if (proposal.progress) {
    proposal.progress((event: { loaded: number; total: number }) =>
      progress(file, event)
    )
  }
}

function progress(
  file: { file: File; name: string },
  { loaded, total }: { loaded: number; total: number }
) {
  fileTemp.value.progress = reactive(makeProgress(loaded, total))
}

function makeProgress(a: number, b: number) {
  return reactive(new ProgressDescriptor(a, b))
}
function clearFile() {
  if (fileProgress.value && fileProgress.value?.cancel) {
    fileProgress.value.cancel()
  }
  fileTemp.value = null
  form.value.resume = null
}

const __returned__ = { UiMessageModal, transformFrom, props, t, currentCountryCode, captchaRef, saveCaptcha, isLoad, getCaptcha, validateForm, keyForReload, formRef, form, userAgreement, privacyPolicy, labels, request, send, countriesLoading, countriesList, countriesLoad, countriesSearch, countriesGet, socialOrderRender, deleteSocial, addSocialNetwork, allowedFiles, dialogFiles, openFileDialog, reset, onChange, dropZoneRef, isOverDropZone, fileTemp, fileProgress, onDrop, partition, errors, acceptor, upload, progress, makeProgress, clearFile, defineAsyncComponent, reactive, ref, get useI18n() { return useI18n }, get composeTransformers() { return composeTransformers }, get simplifiers() { return simplifiers }, get useModalOpener() { return useModalOpener }, get useDropZone() { return useDropZone }, get useFileDialog() { return useFileDialog }, get fileUploadResource() { return fileUploadResource }, get useSelectsDynamicOptions() { return useSelectsDynamicOptions }, get nestedValidatorErrorsParser() { return nestedValidatorErrorsParser }, get useSubmit() { return useSubmit }, get vacanciesApi() { return vacanciesApi }, get geoApi() { return geoApi }, get GtagEvents() { return GtagEvents }, get MAXIMUM_SIZE_FILE_VACANCY() { return MAXIMUM_SIZE_FILE_VACANCY }, get ProgressDescriptor() { return ProgressDescriptor }, get filelistToArray() { return filelistToArray } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})