import { defineComponent as _defineComponent } from 'vue'
import { defineAsyncComponent, ref } from 'vue'

import { useSubmit, nestedValidatorErrorsParser } from '@/composables/useSubmit'
import { useI18n } from 'vue-i18n'
import useField from '@/composables/useField'
import { useModalOpener } from '@/composables/useModalOpener'
import { geoApi } from '@/services/geo.service'
import { financingApplicationsApi } from '@/services/financingApplications.service'
import { useSelectsDynamicOptions } from '@/composables/useSelectsDynamicOptions'

import { composeTransformers, simplifiers } from '@/utils/transformers'


export default /*@__PURE__*/_defineComponent({
  __name: 'applyingForFinancingFirst',
  props: {
    finishFn: {},
    initial: {}
  },
  emits: ["finish", "update:step"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const financingApplicationsTransformFrom = composeTransformers(
  simplifiers.simple('offerId'),
  simplifiers.simple('commercialOfferId'),
  simplifiers.same('targetCountryId'),
  simplifiers.same('downPayment'),
  simplifiers.same('period'),
  simplifiers.same('scheduleType'),
  simplifiers.same('financingType'),
  simplifiers.same('contactFirstName'),
  simplifiers.same('contactLastName'),
  simplifiers.same('contactPhone'),
  simplifiers.same('contactEmail'),
  simplifiers.select('positionInCompany'),
  simplifiers.same('agreeTerms'),
  simplifiers.same('agreePromotional'),
  simplifiers.same('recaptcha')
)

const props = __props

const emit = __emit

const currentCountryCode = window.country_code

const formRef = ref(null)
const { t } = useI18n()

const userAgreement = window.user_agreement
const privacyPolicy = window.privacy_policy

const form = ref({
  ...props.initial,
  contactFirstName: '',
  contactLastName: '',
  contactPhone: '',
  contactEmail: '',
  positionInCompany: null,
  agreeTerms: false,
  agreePromotional: false,
  recaptcha: null,
})

const isLoad = ref(false)
const {
  loading: countriesLoading,
  list: countriesList,
  load: countriesLoad,
  debounceSearch: countriesSearch,
  get: countriesGet,
} = useSelectsDynamicOptions(geoApi.countriesList)
countriesLoad()
const {
  loading: companyPositionsLoading,
  list: companyPositionsList,
  load: companyPositionsLoad,
  debounceSearch: companyPositionsSearch,
  get: companyPositionsGet,
} = useSelectsDynamicOptions(financingApplicationsApi.positionInCompanyList)
companyPositionsLoad()

const labels = {
  contactFirstName: t('First name'),
  contactLastName: t('Last name'),
  middleName: t('Middle name'),
  contactEmail: t('Email address'),
  confirmContactEmail: t('Confirm email address'),
  contactPhone: t('Phone number'),
  companyPositions: t('Your position in the company'),
  country: t('Country'),
  companyName: t('Company name'),
}

const captchaRef = ref(null)

const getCaptcha = async () => {
  await captchaRef.value.execute()
}
const validateForm = async (f, action) => {
  // emit('update:step', { id: 74 })
  const { valid } = (await f())
  if (valid) {
    try {
      isLoad.value = true
      await action()
    } catch (e) {
      isLoad.value = false
      console.warn(e);
    }
  }
}

const saveCaptcha = async (val, setErrors) => {
  form.value.recaptcha = val
  await request(form.value, { setErrors })
}

const request = async (sendData, control) => {
  const transformed = financingApplicationsTransformFrom(form.value)
  isLoad.value = true
  return useSubmit(send(transformed), control, nestedValidatorErrorsParser)
}

const send = async (sendData) => {
  try {
    const {
      data: {
        item: { id },
      },
    } = await financingApplicationsApi.applyingForFinancing.execute(
      {},
      sendData
    )
    if (captchaRef.value) {
      captchaRef.value.reset()
    }
    isLoad.value = false
    emit('update:step', { id })
  } catch (e) {
    isLoad.value = false
    captchaRef.value.reset()
    throw e
  }
}

const __returned__ = { financingApplicationsTransformFrom, props, emit, currentCountryCode, formRef, t, userAgreement, privacyPolicy, form, isLoad, countriesLoading, countriesList, countriesLoad, countriesSearch, countriesGet, companyPositionsLoading, companyPositionsList, companyPositionsLoad, companyPositionsSearch, companyPositionsGet, labels, captchaRef, getCaptcha, validateForm, saveCaptcha, request, send, defineAsyncComponent, ref, get useSubmit() { return useSubmit }, get nestedValidatorErrorsParser() { return nestedValidatorErrorsParser }, get useI18n() { return useI18n }, get useField() { return useField }, get useModalOpener() { return useModalOpener }, get geoApi() { return geoApi }, get financingApplicationsApi() { return financingApplicationsApi }, get useSelectsDynamicOptions() { return useSelectsDynamicOptions }, get composeTransformers() { return composeTransformers }, get simplifiers() { return simplifiers } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})