
import { ref, defineComponent, SetupContext } from 'vue'

export default defineComponent({
  setup(props, { slots, attrs }: SetupContext) {
    const isActive = ref(false)
    const activeRootIndex = ref(null)
    const activeChildIndex = ref(null)
    const activeGrandIndex = ref(null)

    function toggleMenu(state) {
      if (undefined === state) {
        isActive.value = !isActive.value
      } else {
        isActive.value = state
      }
      document.body.classList[isActive.value ? 'add': 'remove']('is-active-categories-menu')
      setActiveGrand(null)
      setActiveChild(null)
      setActiveRoot(null)
    }
    function setActiveChild(index) {
      activeChildIndex.value = index;
    }
    function setActiveGrand(index) {
      activeGrandIndex.value = index;
    }
    function setActiveRoot(index) {
      // setActiveChild(0)
      activeRootIndex.value = index
    }


    return () => slots.default?.({
      isActive: isActive.value,
      activeRootIndex: activeRootIndex.value,
      activeChildIndex: activeChildIndex.value,
      activeGrandIndex: activeGrandIndex.value,
      toggleMenu,
      setActiveRoot,
      setActiveChild,
      setActiveGrand,
    })

  },
})
