import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, nextTick, watch, ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'RangeWidget',
  props: {
    filter: {},
    config: {},
    available: {},
    modelValue: {}
  },
  emits: ["commit", "update:model-value", "change:model-value"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const defaultSliderOptions = {
  tooltip: 'none',
  contained: true,
  'dot-size': 16,
}

const props = __props

const emit = __emit

const internal = ref({
  min: Number(
    undefined === props.modelValue?.min
      ? props.filter.min
      : props.modelValue?.min
  ),
  max: Number(
    undefined === props.modelValue?.max
      ? props.filter.max
      : props.modelValue?.max
  ),
})

const min = computed(() => {
  const _min =
    (props.available?.min && Number(props.available?.min)) ||
    Number(props.filter?.min)
  return props.modelValue?.min ? Number(props.filter?.min) : _min
})
const max = computed(() => {
  const _max =
    (props.available?.max && Number(props.available?.max)) ||
    Number(props.filter?.max)
  return props.modelValue?.max ? Number(props.filter?.max) : _max
})

watch(
  [() => props.available, () => props.modelValue],
  ([newAvailable, newModel], [oldAvailable, oldModel]) => {
    if (
      JSON.stringify(newModel) !== JSON.stringify(internal) ||
      newModel?.min ||
      newModel?.max
    ) {
      emit('update:state', 
        !!(props.modelValue?.min || props.modelValue?.max)
      )
      if (newModel?.min || newModel?.max) {

        // const min = Number(props.filter.min) >= Number(newAvailable?.min) ? Number(props.filter.min) : Number(newAvailable?.min)
        // const max = Number(props.filter.max) < Number(newAvailable?.max) ? Number(props.filter.max) : Number(newAvailable?.max)

        // internal.value.min = undefined === newModel?.min
        //   ? min : Number(newModel.min) < min ? min : Number(newModel.min)
        // internal.value.max = undefined === newModel?.max
        //   ? max : Number(newModel.max) > max ? max : Number(newModel.max)
        internal.value.min = Number(newModel.min)
        internal.value.max = Number(newModel.max)
      } else {
        internal.value.min = Number(newAvailable?.min)
        internal.value.max = Number(newAvailable?.max)
      }
    } else if (JSON.stringify(newAvailable) !== JSON.stringify(oldAvailable)) {
      internal.value.min =
        Number(internal.value?.min) < Number(newAvailable?.min)
          ? Number(newAvailable?.min)
          : internal.value.min
      internal.value.max =
        Number(internal.value?.max) > Number(newAvailable?.max)
          ? Number(newAvailable?.max)
          : internal.value.max
    }
    // const availableMax = +props.available?.max
    // const availableMin = +props.available?.min
    // const currentMax = +internal.value.max
    // const currentMin = +internal.value.min
    // const inRangeMax = Number(props.filter.max) > internal.value.max > Number(props.filter.min)
    // if (availableMax > currentMax < availableMin  || internal.value.min > Number(props.filter.min)) {
    //   updateValue([
    //     internal.value.max < Number(props.filter.max) ? Number(props.filter.max) : internal.value.max,
    //     internal.value.min > Number(props.filter.min) ? Number(props.filter.min) : internal.value.min
    //   ])
    // }
  },
  { deep: true }
)

const debounceInput = useDebounceFn((q) => updateValue(q), 700)


onMounted(() => {
  emit('update:state', 
    !!(props.modelValue?.min || props.modelValue?.max)
  )
})

function handleUpdate(value) {
  const [min, max] = value
  internal.value.min = min
  internal.value.max = max
  debounceInput(value)
}

function updateValue(value: [number, number]) {
  const [min, max] = value
  internal.value.min = min > max ? max : min
  internal.value.max = max < min ? min : max
  nextTick(() => {
    emit('change:model-value', internal.value)
    apply()
  })
}
function apply() {
  emit('commit')
}

const __returned__ = { defaultSliderOptions, props, emit, internal, min, max, debounceInput, handleUpdate, updateValue, apply, computed, onMounted, nextTick, watch, ref, get useDebounceFn() { return useDebounceFn } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})