import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from 'vue'

const p = 'password'
const t = 'text'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'RootInput',
  props: {
  id: {
    type: String,
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  innerElement: {
    type: String,
    default: 'input',
  },
  blockName: {
    type: String,
    default: 'control-input',
  },
  variant: {
    type: Array,
    default: () => [],
  },
  size: {
    type: Array,
    default: () => ['sm'],
  },
  styling: {
    type: String,
    default: 'default',
  },
  appearance: {
    type: Array,
    default: () => [],
  },
  view: {
    type: String,
    default: '',
  },
  iconSize: {
    type: [String, Array],
    default: '2xl',
  },
  startIcon: {
    type: String,
    default: '',
  },
  endIcon: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  focus: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Number, Object, Array],
    default: '',
  },
  invalid: Boolean,
},
  setup(__props, { expose: __expose }) {
  __expose();



const props = __props

const blockClasses = computed(() => {
  type Classes = Record<string, boolean | string>
  const sizesClass: Classes = props.size.reduce(
    (acc: Classes, size: string) => {
      acc[`${props.blockName}--size_${size}`] = true
      return acc
    },
    {} as Classes
  )
  const appearanceClass: Classes = props.appearance.reduce(
    (acc: Classes, appearance: string) => {
      acc[`${props.blockName}--appearance_${appearance}`] = true
      return acc
    },
    {} as Classes
  )
  const variantClass: Classes = props.variant.reduce(
    (acc: Classes, variant: string) => {
      acc[`${props.blockName}--variant_${variant}`] = true
      return acc
    },
    {} as Classes
  )
  return {
    [`${props.blockName}`]: true,
    [`${props.blockName}--styling_${props.styling}`]: props.styling,
    ...sizesClass,
    ...appearanceClass,
    ...variantClass,
    [`${props.blockName}--view_${props.view}`]: props.view,
    [`is-disabled`]: props.disabled,
    [`is-readonly`]: props.readonly,
    [`is-invalid`]: props.invalid,
    [`is-focus`]: props.focus,
    [`is-password`]: isPasswordField,
  }
})

const inputType = ref(props.type)

const isPasswordField = inputType.value === p

const toggleType = () => (inputType.value = inputType.value === p ? t : p)

const __returned__ = { props, blockClasses, p, t, inputType, isPasswordField, toggleType, computed, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})