import { defineComponent as _defineComponent } from 'vue'
import {
  computed,
} from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RootElement',
  props: {
  tag: {
    type: String,
    default: 'button',
  },
  blockName: {
    type: String,
    default: 'control-button',
  },
  variant: {
    type: [String, Array],
    default: () => [],
  },
  size: {
    type: [Array, String],
    default: () => ['md'],
  },
  styling: {
    type: String,
    default: 'default',
  },
  appearance: {
    type: Array,
    default: () => [],
  },
  view: {
    type: [String, Array],
    default: '',
  },
  iconSize: {
    type: [String, Array],
    default: '2xl',
  },
  startIcon: {
    type: String,
    default: '',
  },
  endIcon: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

function generateBlockClass(modifierValue: string[], modifier: string) {
  if (!modifierValue) return {}
  if (Array.isArray(modifierValue)) {
    return modifierValue.reduce((acc, value) => {
      acc[`${props.blockName}--${modifier}_${value}`] = true
      return acc
    }, {})
  } else {
    return { [`${props.blockName}--${modifier}_${modifierValue}`]: true }
  }
}

const blockClasses = computed(() => { 
  return {
    [`${props.blockName}`]: true,
    ...generateBlockClass(props.styling, 'styling'),
    ...generateBlockClass(props.size, 'size'),
    ...generateBlockClass(props.appearance, 'appearance'),
    ...generateBlockClass(props.variant, 'variant'),
    ...generateBlockClass(props.view, 'view'),
    [`is-disabled`]: props.disabled,
    [`is-readonly`]: props.readonly,
    [`is-invalid`]: props.invalid,
  }
})

const __returned__ = { props, generateBlockClass, blockClasses, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})