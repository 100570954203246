import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import TagsWidget from './widgets/TagsWidget.vue'
import SelectWidget from './widgets/SelectWidget.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogFilters',
  props: {
    filtersList: {},
    parameters: {}
  },
  emits: ["update:parameters"],
  setup(__props: any, { expose: __expose }) {
  __expose();





const WIDGETS_MAP = {
  select: {
    component: SelectWidget,
  },
} as const


const __returned__ = { WIDGETS_MAP, ref, TagsWidget, SelectWidget }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})