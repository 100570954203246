import { defineComponent as _defineComponent } from 'vue'
import { equals } from 'ramda'
import { useI18n } from 'vue-i18n'
import { onMounted, ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsWidget',
  props: {
    name: {},
    items: {},
    title: {},
    value: {}
  },
  emits: ["input"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const { t } = useI18n()

const options = ref([...props.items])

const internal = ref()
onMounted(() => {
  internal.value = getInternal()
})

watch(
  () => props.value,
  () => {
    internal.value = getInternal()
  }
)

const getInternal = () => {
  if (props.value && options.value) {
    if (Array.isArray(props.value)) {
      if (
        equals(
          [...props.value].sort(),
          props.items.map((el) => el.value).sort()
        )
      ) {
        return props.items.map((el) => el.value)
      } else {
        return props.value || []
      }
    }

    return (
      props.value &&
      options.value.find((el) => {
        return el.value === props.value
      })?.value
    )
  }
  return null
}
// watch(internal, () => {
//   emit('input', internal.value)
// })

function handleChange(event: any, item: any) {
  if (event.target.checked) {
    internal.value = item.value
  } else {
    internal.value = []
  }
  update()
}
function handleChangeAll(event: any) {
  if (event.target.checked) {
    internal.value = []
  } else {
    internal.value = []
  }
  update()
}
function update() {
  emit('input', internal.value)
}

const __returned__ = { props, emit, t, options, internal, getInternal, handleChange, handleChangeAll, update, get equals() { return equals }, get useI18n() { return useI18n }, onMounted, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})